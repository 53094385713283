import styled from "styled-components";
// import { props.icon } from "@mui/icons-material";
// import { Map, AcUnit, Star, House } from "@mui/icons-material";
import * as Icon from "@mui/icons-material";

export const StyledIcon = styled(Icon.Star)`
  /* background: ${(props) => props.inputColor || "tomato"};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 0.625rem; */
`;
