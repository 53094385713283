export const colors = {
  tomato2: "tomato",
  black: "#333",
  white: "#fff",
  violetred: "palevioletred",
  grey: "grey",
  darkblue: "darkblue",
  palegreen: "palegreen",
  lightblue: "lightblue",
};
