import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
`;
